import React, {Component} from "react"
import Button from "@material-ui/core/Button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArrowForwardIcon from "@material-ui/icons/Forward"
import ArrowForwardThinIcon from "@material-ui/icons/ArrowForward"
import {Articles} from "../components/learning-library/articles"
import Modal from "@material-ui/core/Modal"
import {isValidContentfulImage, isValidSectionField} from "../common/utils/CommonUtils";
import {graphql} from 'gatsby';
import "./home-page.scss"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import "./learning-library.scss"

class IndexPage extends Component {

    constructor(props) {

        super(props);
        this.state = {
            openModal: false,

            educationalContentItems: [],
            filteredEducationalItems: [],

            loader: {
                options: {
                    position: 'absolute'
                }
            }

        };

        this.pageData = this.getFirstContentfulNode(this.props,
            'allContentfulHomePage');
    }

    getFirstContentfulNode = (props, key) => {
        return props.data[key].nodes[0];
    };

    componentDidMount() {
        window.analytics.page("Home");
    }

    render() {
        const {topSection, shortSections} = this.pageData;
        return (
            <Layout location={this.props.location}>

                <SEO title="Home"/>
                <div className="get-started-home-wrapper">
                    <div className="get-started-home-inner">
                        <div className="get-started-text-wrap">
                            <StaticImage className="get-started-home-pattern"
                                 src={'../assets/images/home-bg-circles.png'}
                                 alt="Pattern"/>
                            {
                                isValidSectionField(topSection, "title") &&
                                <h1 className="blackHeading2">{topSection.title}</h1>
                            }
                            {
                                isValidSectionField(topSection, "description")
                                &&
                                <p className="parah"
                                   dangerouslySetInnerHTML={{__html: topSection.description.description}}></p>
                            }
                            <Button onClick={() => {

                            }}
                                    href={"/get-started"}
                                    className="blue-arrow-btn" color="primary">
                                Get Started <ArrowForwardIcon/>
                            </Button>
                        </div>
                        <div className="get-started-anim-wrap">
                            {
                                isValidContentfulImage(topSection.imageOrVideo)
                                && (
                                    <GatsbyImage className="get-started-home-hero-img"
                                                 image={getImage(topSection.imageOrVideo)}
                                         // src={topSection.imageOrVideo?.file?.url}
                                         alt="providerImg"/>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="feature-wrapper">
                    {topSection.featuredIcons && topSection.featuredIcons.length
                    > 0 && (
                        <p className="feature-text">FEATURED IN</p>
                    )}
                    <ul className="feature-ul">
                        {
                            topSection.featuredIcons
                            && topSection.featuredIcons.map((icon) => (
                                <li className="feature-item">
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://classifieds.usatoday.com/press/how-covid-19-has-impacted-opioid-abuse-in-the-united-states/">

                                        {isValidContentfulImage(icon) && (
                                            <img className="feature-img"
                                                 src={icon.file.url}
                                                 alt="USA Today"/>
                                        )
                                        }
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="new-home-manifesto-wrapper">
                    <div className="new-home-manifesto-inner">
                    </div>

                    <h2 className="new-home-manifesto-text">No matter what your
                        goal is around drugs, alcohol,
                        and mental health, we’re here to help you get there</h2>
                    <Button href="/manifesto/" className="new-blue-link"
                            color="primary">
                        Read our Manifesto <ArrowForwardThinIcon/>
                    </Button>
                </div>
                <div className="new-home-features">
                    <div className="new-home-features-inner">

                        {
                            shortSections && shortSections.map(
                                (section, index) => (
                                    <div key={"key-" + section.heading}
                                         className="new-home-single-feature">
                                        {
                                            (index === 0 || index % 2 === 0)
                                            && (
                                                <div
                                                    className="new-feature-img-side">
                                                    <img
                                                        className="new-feature-image"
                                                        src={section.image.file.url}
                                                        alt=" Frame"/>
                                                </div>
                                            )
                                        }
                                        <div className="new-feature-text-side">
                                            {
                                                isValidSectionField(section,
                                                    "heading") &&

                                                <h2 className="feature-heading">
                                                    {section.heading}
                                                </h2>
                                            }

                                            {

                                                isValidSectionField(section,
                                                    "description") &&

                                                <p className="feature-para"
                                                   dangerouslySetInnerHTML={{__html: section.description.description}}></p>
                                            }

                                            {
                                                isValidSectionField(section,
                                                    "linkText") &&

                                                <Button
                                                    href={`/${section.link}`}
                                                    className="new-blue-link"
                                                    color="primary">
                                                    {section.linkText}
                                                    <ArrowForwardThinIcon/>
                                                </Button>
                                            }
                                        </div>
                                        {
                                            index % 2 !== 0 && (
                                                <div
                                                    className="new-feature-img-side"
                                                    style={{textAlign: "right"}}>
                                                    {isValidContentfulImage(
                                                        section.image) && (
                                                        <img
                                                            className="new-feature-image"
                                                            src={section.image.file.url}
                                                            alt=" Frame"/>
                                                    )}

                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                        }
                    </div>
                </div>
                <div className="new-home-interested-wrapper">
                    <div className="new-home-interested-pattern"></div>
                    <h2 className="interested-heading">What are you interested
                        in?</h2>
                    <p className="interested-sub-heading">We offer different
                        services to meet your needs. There’s no
                        such thing as a “one-size-fits-all” approach at
                        Confidant.</p>
                    <div className="list-gradient-bg">
                        <div className="new-home-interested-inner">
                            <div className="interested-list">
                                <div className="single-interested-box">
                                    <img className="new-feature-image"
                                         src={require(
                                             '../assets/images/provider-prescriber.svg')}
                                         alt="Providers & Prescriber"/>
                                    <Button href="/online-therapy/"
                                            className="new-blue-link"
                                            color="primary">
                                        Providers &
                                        Prescribers <ArrowForwardThinIcon/>
                                    </Button>
                                </div>

                                <div className="single-interested-box">
                                    <img className="new-feature-image"
                                         src={require(
                                             '../assets/images/free-support.svg')}
                                         alt="Support Groups"/>
                                    <Button href="/support-groups/"
                                            className="new-blue-link"
                                            color="primary">
                                        Support Groups <ArrowForwardThinIcon/>
                                    </Button>
                                </div>

                                <div className="single-interested-box">
                                    <img className="new-feature-image"
                                         src={require(
                                             '../assets/images/self-directed.svg')}
                                         alt="Self-directed Courses"/>
                                    <Button href="/how-to-guides/"
                                            className="new-blue-link"
                                            color="primary">
                                        Self-directed
                                        Courses <ArrowForwardThinIcon/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-home-slider-wrapper">
                    <div className="new-home-slider-inner">
                        <p className="small-top-heading">The latest</p>
                        <h2 className="main-big-heading">From The Resource
                            Hub</h2>
                        <Articles/>
                    </div>
                </div>
                <div className="new-home-letter-one">
                    <div className="new-home-letter-one-inner">
                        <StaticImage className="letter-one-image"
                             src={'../assets/images/home-letter-love.svg'}
                             alt="home letter"/>
                        <h2 className="home-letter-one-heading">Letter to Loved
                            Ones</h2>
                        <p className="home-letter-one-para">Addiction impacts
                            the whole family.<br/>
                            That’s why we offer services for everyone. </p>
                        <Button href="/letter-to-loved-ones/"
                                className="new-blue-link" color="primary">
                            Read the Letter <ArrowForwardThinIcon/>
                        </Button>
                    </div>
                </div>
                <Modal
                    className="modalStyle1"
                    open={this.state.openModal || (typeof window !== 'undefined'
                        && window.location && window.location.hash
                        === "#get-started")}
                    disableScrollLock={true}>
                </Modal>
            </Layout>
        )
    }
}

export default IndexPage

export const query = graphql`
query getHomePageData {
  allContentfulHomePage {
    nodes {
      id
      topSection {
      title
       ... on ContentfulPageTopSection {
        description {
          description
        }
       featuredIcons {
          file {
            url
          }
        }
        imageOrVideo {
          file {
            url
          }
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      }
          shortSections {
        heading
        link
        linkText
        image {
          file {
            url
          }
          gatsbyImageData(layout: FULL_WIDTH)
          description
        }
        description {
          description
        }
      }
    }
  }
}


`;
